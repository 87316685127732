import React, { useState } from 'react'
import elps from "../../../assets/svg/elps.svg"
import blank_elps from "../../../assets/svg/blank_elps.svg"
const KnowCheck = () => {
    const [elpsFirst, setElpsFirst] = useState();
    const [elpsSecond, setElpsSecond] = useState();
    const elpsHandlerFirst = () => {
        setElpsFirst(!elpsFirst)
    }
    const elpsHandlerSecond = () => {
        setElpsSecond(!elpsSecond)
    }
    return (
        <div className="amount_image">
            <p>Last Contained</p>
            <div className="amount_img_1">
                <div onClick={elpsHandlerFirst}>
                    {elpsFirst ?
                        (<img src={elps} alt="solid img" />)
                        :
                        (<img src={blank_elps} alt="solid img" />)
                    }
                </div>
                <p>water</p>
            </div>
            <div className="amount_img_2">
                <div onClick={elpsHandlerSecond}>
                    {elpsSecond ?
                        (<img src={elps} alt="solid img" />)
                        :
                        (<img src={blank_elps} alt="solid img" />)}
                </div>
                <p>hazard</p>
            </div>
        </div>
    )
}
export default KnowCheck;